<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>公众号用户</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="昵称">
                                <el-input v-model="search.nickname"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="关注时间">
                                <el-date-picker
                                        style="width: 100%"
                                        v-model="subscribe_time"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="openid"
                        label="openid">
                </el-table-column>
                <el-table-column
                        prop="unionid"
                        label="unionid">
                </el-table-column>
                <el-table-column
                        prop="nick_name"
                        label="昵称">
                </el-table-column>
                <el-table-column
                        prop="avatar_url"
                        label="头像">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 22px; height: 22px; margin-right: 4px;"
                                :src="scope.row.avatar_url"
                                :preview-src-list="[scope.row.avatar_url]"
                                fit="contain">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="手机号">
                </el-table-column>
                <el-table-column
                        prop="subscribe_time"
                        label="关注时间">
                </el-table-column>
                <el-table-column
                        prop="is_subscribe"
                        label="关注状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_subscribe===1">已关注</el-tag>
                        <el-tag size="mini" v-if="scope.row.is_subscribe===0" type="danger">未关注</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="add_time"
                        label="添加时间">
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="修改时间">
                </el-table-column>
                <!--                <el-table-column-->
                <!--                        fixed="right"-->
                <!--                        label="操作"-->
                <!--                        width="100">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-button-->
                <!--                                v-if="is_auth('coach.coachpool.getinfo')"-->
                <!--                                @click="tosee(scope.row.coach_uuid)"-->
                <!--                                size="mini">查看-->
                <!--                        </el-button>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '公众号用户',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            subscribe_time: null,   // 时间条件
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                nickname: '',
            }
            this.subscribe_time = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "wxmp.user.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            console.log('postdata', postdata)
            // 判断时间条件
            if (this.subscribe_time !== null) {
                postdata.starttime = this.subscribe_time[0]
                postdata.endtime = this.subscribe_time[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                console.log('json', json)
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(coach_uuid = '') {
            this.$router.push({path: '/mpwx/details', query: {coach_uuid: coach_uuid}})
        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
